<div class="govuk-accordion__section" [class.govuk-accordion__section--expanded]="isExpanded$ | async">
  <div
    class="govuk-accordion__section-header"
    (click)="isExpanded.next(!isExpanded.value)"
    (keydown)="isExpanded.next(!isExpanded.value)"
    tabindex="0"
  >
    <h2 class="govuk-accordion__section-heading">
      <button
        type="button"
        class="govuk-accordion__section-button"
        [attr.aria-controls]="contentId"
        [attr.aria-expanded]="isExpanded$ | async"
        [attr.aria-label]="
          header + ' , ' + ((isExpanded$ | async) === true ? 'Hide this section' : 'Show this section')
        "
        (focusin)="isFocused = true"
        (blur)="isFocused = false"
      >
        <span class="govuk-accordion__section-heading-text" [id]="accordion.id + '-heading-' + itemIndex">
          <span class="govuk-accordion__section-heading-text-focus">
            {{ header }}
          </span>
        </span>
        <span class="govuk-visually-hidden govuk-accordion__section-heading-divider">, </span>

        <span
          *ngIf="accordionItemSummaryDirective"
          [id]="accordion.id + '-summary-' + itemIndex"
          class="govuk-accordion__section-summary govuk-body"
        >
          <ng-content select="[govukAccordionItemSummary]"></ng-content>
          <span class="govuk-visually-hidden govuk-accordion__section-heading-divider">, </span>
        </span>

        <span class="govuk-accordion__section-toggle" data-nosnippet=""
          ><span class="govuk-accordion__section-toggle-focus"
            ><span
              class="govuk-accordion-nav__chevron"
              [class.govuk-accordion-nav__chevron--down]="(isExpanded$ | async) === false"
            ></span
            ><span class="govuk-accordion__section-toggle-text">{{
              (isExpanded$ | async) ? 'Hide' : 'Show'
            }}</span></span
          ></span
        >
      </button>
    </h2>
  </div>
  <div [id]="contentId" class="govuk-accordion__section-content">
    <ng-content></ng-content>
  </div>
</div>
