<div class="govuk-phase-banner">
  <div class="govuk-phase-banner__content" [class.align-right]="tagAlign === 'right'">
    <strong
      *ngIf="phase"
      class="govuk-tag govuk-phase-banner__content__tag"
      [class.order-2]="tagAlign === 'right'"
      [style.background-color]="tagColor"
    >
      {{ phase }}
    </strong>
    <span class="govuk-phase-banner__text">
      <ng-content></ng-content>
    </span>
  </div>
</div>
